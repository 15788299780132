import React, { Component } from 'react';
import '../App.css';
import founder from '../assets/founder.png';
import artist from '../assets/LadyShark.jpg';
import team from '../assets/team.png';
import link from '../assets/linkedin.png';

const linkedin = () => {
	window.open("https://www.linkedin.com/in/giorgiopasqualin");
}

function reveal() {
  var reveals = document.querySelectorAll(".reveal");

  for (var i = 0; i < reveals.length; i++) {
    var windowHeight = window.innerHeight;
    var elementTop = reveals[i].getBoundingClientRect().top;
    var elementVisible = 15;

    if (elementTop < windowHeight - elementVisible) {
      reveals[i].classList.add("active");
    } else {
      reveals[i].classList.remove("active");
    }
  }
}

window.addEventListener("scroll", reveal);


class Team extends Component {

  async componentDidMount() {

  }

  render() {
    return (
      <div class="boxWrap4">

        <div class="storyH"><img src={team} /></div>

        <div class="teamSection">
          <div class="team1">
            <div class="manDiv"><img class="man" src={founder} /></div>
            <div class="tAndName">
              <div class="memName">TOUGH SHARK!</div>
              
              <div class="memName2">(Giorgio Pasqualin)</div>
              <div><img class="linkedin" onClick={linkedin} src={link}/></div>

              <div class="memName">FOUNDER</div>

            </div>
            <div class="memNamePosition">Oil & Gas Industry Vet, with 26-years of global working experience executing large, and highly complex “Mega-Projects” in tough locations around the world, with project values ranging from 2.5 to 10+ Billion USD. Now, Certified Blockchain Expert by the Blockchain Council, and an avid NFT enthusiast.</div>
          </div>

          <div class="team1">
            <div class="manDiv"><img class="man" src={artist} /></div>
            <div class="tAndName">

            </div>
            <div class="memNamePosition">Open opportunities coming soon.</div>

          </div>

        </div>
      </div>
    )
  }
}

export default Team;