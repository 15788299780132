import React, { Component } from 'react';
import '../App.css';
import line from '../assets/roadmap-line2.png';
import shark from '../assets/HappyShark.png';
import rm from '../assets/Roadmap.png';
import box from '../assets/box-3.png';


class Roadmap extends Component {


    render() {
        return (

            <div class="boxWrap5">

                <div class="storyHRmM"><img src={rm} /></div>

                <div class="rmMain">
                    <div class="storyRm"><img src={rm} /></div>

                    <div class="rmP2"><img class="rmParrot" src={shark} />
                        <img class="line" src={line} />
                        <img class="box" src={box} /></div>

                    <div class="rmboxes">
                        <div class="rmBoxMain">
                            <div class="rmBox" data-aos="fade-down" data-aos-duration="1500">
                                <p><span class="year">PHASE 1: (Starting NOW until Collection Mints Out)</span></p>
                                <p>Bored Sharks Club Re-Launch: Revised Mint Price (0.077 ETH)</p>
                                <p>Strengthen Twitter/Instagram Presence and Engage with the Community</p>
                                <p>Continue to Grow our Discord Channel for Bored Sharks Club Members</p>
                                <p>Regularly Host Weekly Twitter Spaces and AMAs to Connect with the Community</p>
                                <p>Mint Day: MINTING is OPEN</p>
                                <p>eBook Distribution: "How to Supercharge Your Career - To Become a CEO"</p>
                                <p>Grand Prize of 2 ETH and two AKCB NFTs for 2nd and 3rd Place Winners (Upon Collection Mint Out)</p>
                                <p>Develop Promotions and Partnerships</p>
                                <p>Podcast Development and Inclusive Participation for All Minters</p>
                                <p>Awarding OG-Shark Status for All Minters</p>
                                <p>Plan and Preview Next Collection (Full 3D)</p>
                                <p>Hire and Empower Enthusiastic Bored Sharks Club Members for Discord Channel Management and Social Media Feeds Management</p>
                            </div>
                        </div>

                        <div class="rmP"><img class="rmParrot" src={shark} />
                            <img class="line" src={line} />
                            <img class="box" src={box} /></div>

                        <div class="rmBoxMain2">


                            <div class="rmBox" data-aos="fade-up" data-aos-duration="1500">
                                <p><span class="year">PHASE 2: (To Be Announced Upon Collection Mint Out)</span></p>
                                <p>Exciting Initiatives and Opportunities for the Bored Sharks Club Community</p>
                                <p>Expansion into IRL Events and Networking Activities</p>
                                <p>Continued Focus on Personal Growth and Career Development</p>
                                <p>Collaborations with Industry Leaders and Subject Matter Experts</p>
                                <p>And Much More – Stay Tuned for Updates!</p>
                            </div>
                          

                        </div>
                    </div>

                    <div class="rmboxesMobile">
                        <div class="rmBoxMain">
                            <div class="rmBox" data-aos="fade-down" data-aos-duration="1500">
                                <p><span class="year">PHASE 1: (Starting NOW until Collection Mints Out)</span></p>
                                <p>Bored Sharks Club Re-Launch: Revised Mint Price (0.077 ETH)</p>
                                <p>Strengthen Twitter/Instagram Presence and Engage with the Community</p>
                                <p>Continue to Grow our Discord Channel for Bored Sharks Club Members</p>
                                <p>Regularly Host Weekly Twitter Spaces and AMAs to Connect with the Community</p>
                                <p>Mint Day: MINTING is OPEN</p>
                                <p>eBook Distribution: "How to Supercharge Your Career - To Become a CEO"</p>
                                <p>Grand Prize of 2 ETH and two AKCB NFTs for 2nd and 3rd Place Winners (Upon Collection Mint Out)</p>
                                <p>Develop Promotions and Partnerships</p>
                                <p>Podcast Development and Inclusive Participation for All Minters</p>
                                <p>Awarding OG-Shark Status for All Minters</p>
                                <p>Plan and Preview Next Collection (Full 3D)</p>
                                <p>Hire and Empower Enthusiastic Bored Sharks Club Members for Discord Channel Management and Social Media Feeds Management</p>
                            </div>

                            <div class="rmBox" data-aos="fade-up" data-aos-duration="1500">
                                <p><span class="year">PHASE 2: (To Be Announced Upon Collection Mint Out)</span></p>
                                <p>Exciting Initiatives and Opportunities for the Bored Sharks Club
                                    Community</p>
                                <p>Expansion into IRL Events and Networking Activities</p>
                                <p>Continued Focus on Personal Growth and Career Development</p>
                                <p>Collaborations with Industry Leaders and Subject Matter Experts</p>
                                <p>And Much More – Stay Tuned for Updates!</p>

                            </div>

                        </div>

                        <div class="rmP"><img class="rmParrot" src={shark} />
                            <img class="line" src={line} />
                            <img class="box" src={box} /></div>

                    </div>

                </div>

            </div>


        );

    }
}

export default Roadmap;