import React, { Component, useEffect } from 'react';
import '../App.css';
import "aos/dist/aos.css";
import Aos from 'aos';
import shark from '../assets/HappyShark.png';
import intro from '../assets/Introduction.png'

const Stry = () => {
    useEffect(() => {
        Aos.init({ duration: 4000 });
    }, [])
}

class Story extends Component {

    render() {
        return (

            <div class="boxWrap2">

                <div class="storyH"><img src={intro}/></div>

                <div class="about">
                    <div class="storyCon">
                        <p class="storyCon2">Welcome to the Bored Sharks Club - an NFT project that's all about community, personal growth, and real value. With a limited collection of 777 unique, hand-drawn NFTs, we're building something special, together.</p>
                        <p class="storyCon2">We're not your typical NFT project. The Bored Sharks Club is designed for hard-working professionals seeking a supportive network and genuine opportunities for growth. Instead of flashy events, we're taking a grounded approach and growing step by step, alongside our community.
                        </p>
                        <p class="storyCon2">Here's how we're pivoting to better serve our members:</p>
                        <p>
                        <li class="storyCon2">Minting price reduced to <span class="hlSpan">0.077 ETH</span></li>
                        <li class="storyCon2">Collection size: <span class="hlSpan">777</span>, with <span class="hlSpan">27 Sharks</span> reserved for promotions</li>
                        <li class="storyCon2">Revised, achievable roadmap</li>
                        <li class="storyCon2">Continued focus on Career Development and Personal Growth in <span class="hlSpan">WEB3</span></li>
                        <li class="storyCon2">Free e-book from the developer for minters: <span class="hlSpan">"How to Supercharge Your Career - To Become a CEO"</span></li>
                        <li class="storyCon2">Grand prize of <span class="hlSpan">2 ETH</span> for <span class="hlSpan">one lucky minter</span></li>
                        <li class="storyCon2">Two <span class="hlSpan">AKCB NFTs</span> for 2nd and 3rd place winners</li>
                        <li class="storyCon2"><span class="hlSpan">OG-Shark status</span> for all minters</li>
                        <li class="storyCon2">Community-driven growth without relying on major influencers</li>
                        <li class="storyCon2"><span class="hlSpan">Inclusive podcast series</span> for all minters</li>
                        </p>

                        <p>We believe in the power of community and collaboration. The Bored Sharks Club is here to grow with you, providing value, networking opportunities, and real-world benefits. Let's support each other and make waves together in the world of NFTs and beyond!</p>
                    </div>
                    <img class="shark" src={shark} />

                </div>

            </div>
        )
    }
}

export default Story;

