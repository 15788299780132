import React, { Component } from 'react';
import faq from '../assets/FAQ.png'; 

class FAQ extends Component {


  render() {
    return (

      <div id="faq" class="faqbg">

        <div data-aos="fade-up" class="accordin reveal">

        <div class="storyH"><img src={faq} /></div>

          <details >
            <summary>What is an NFT?</summary>
            <div class="faq__content">
              <p>A NFT (non-fungible token) is a unique digital identifier that cannot be copied, substituted, or subdivided, that is recorded in a blockchain, and that is used to certify authenticity and ownership.</p>
            </div>
          </details>
          <details>
            <summary>How do I buy an NFT?</summary>
            <div class="faq__content">
              <p>There are many ways to buy NFTs. Nowadays, NFTs can be bought on many places and on many platforms. However, the most common NFTs reside on the Ethereum Blockchain; and the most common practice is to buy them via a Minting Process, or on Secondary Markets like Opensea, Rarible, SuperRare and more.</p>
              <p>Typically, the Minting of NFTs offers investors the opportunity to buy the Non Fungible Tokens before they are sold on Secondary Markets.</p>

            </div>
          </details>
          <details>
            <summary>Will the Bored Sharks Club offer a Minting Process?</summary>
            <div class="faq__content">
              <p>Yes, everyone willing to invest on this project will have access to the MINTING page from where the NFTs can be minted. However, keep in mind that there will only be 777 Bored Sharks to mint, and you should be ready to ATTACK on Mint Day.</p>

            </div>
          </details>

          <details>
            <summary>What will the Minting Price be for each Shark?</summary>
            <div class="faq__content">
              <p>ETH</p>

            </div>
          </details>

          <details>
            <summary>What is ETH?</summary>
            <div class="faq__content">
              <p>Eth is the abbreviation for Ethereum, which is a decentralized, open-source blockchain with smart contract functionality. Ether (ETH) is the native cryptocurrency of the platform. Among cryptocurrencies, ether is second only to bitcoin in market capitalization.</p>

            </div>
          </details>


          <details>
            <summary>How can I buy ETH to Mint?</summary>
            <div class="faq__content">
              <p>First of all, you are going to need to have a Crypto Wallet address to buy and receive your Crypto. Please get in touch if you need help on how to create a Crypto Wallet. But in any case, you MUST always keep this in mind, “A TRUE SHARK NEVER, EVER REVEALS HIS/HER SECRET PHRASE TO ANYONE!” Not even to the Tough Shark!</p>
              <p>One of the most common Wallet providers and that is most used for buying NFTs is METAMASK. However, other wallet providers like TRUST Wallet, or Birdie Wallet can be used too. Please get in touch if you need help or clarifications with this.</p>
            </div>
          </details>

          <details>
            <summary>When ALLOWLIST?</summary>
            <div class="faq__content">
              <p>NEVER! The Bored Sharks Club will not play that game. Whoever is really interested in joining and investing into this project will have the same opportunity to mint as any other investors will. Everybody will have an equal chance for minting, but when the 777 Sharks will be all minted, that is it, there will not be any more opportunities to MINT the Genesis Sharks.</p>

            </div>
          </details>

          <details>
            <summary>ARE Results Guaranteed?</summary>
            <div class="faq__content">
              <p>100% NO! There is no guarantee that you will become filthy rich by buying Bored Sharks Club NFTs. There is no guarantee that these NFTs will become the hottest thing after sliced bread with butter. In fact, if you are looking for those kinds of offers, please do yourself a favor and go find ‘em elsewhere.</p>
              <p>However, what is guaranteed is that Giorgio Pasqualin (The Founder) will do his very best to ensure the goals as set forth on the project roadmap will be achieved, and by achieving these the holders will greatly benefit from the many things planned for the near future.</p>
              <p>There will be challenges ahead to overcome, but “Tough Shark” lives and breathes for a good challenge to surmount.</p>

            </div>
          </details>

          <details>
            <summary>For whom is the Bored Sharks Club intended?</summary>
            <div class="faq__content">
              <p>Although anybody is welcome to join, The Bored Sharks Club was conceived for the Working Professionals out there. Yes, those working professionals that live on the 9 to 5 schedule (or similar), and that must do their best to keep climbing on the corporate ladders to growth and provide the best for themselves, and their beloved ones.</p>
              <p>This project is not for the party animals who just live to party. We are called Bored for a reason, and that is because WE HAVE TO WORK to get paid.</p>
              <p>So if you work, have aspirations in life, and want your best for your future but somehow, you’re feeling stuck without knowing what else to do to maximize your career potential, then this is the club for you.</p>

            </div>
          </details>

          <details>
            <summary>WHEN WILL THIS PROJECT LAUNCH?</summary>
            <div class="faq__content">
              <p>TBA</p>

            </div>
          </details>

        </div>
      </div>


    )
  }
}

export default FAQ;

