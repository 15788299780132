import React, { Component, useEffect } from 'react';
import '../App.css';
import "aos/dist/aos.css";
import Aos from 'aos';
import ourStory from '../assets/Our-Story.png'

const Stry = () => {
    useEffect(() => {
        Aos.init({ duration: 4000 });
    }, [])
}


class Story2 extends Component {

    render() {
        return (

            <div class="boxWrap3">

                <div class="storyH"><img src={ourStory} /></div>

                <div class="about2">
                    <div class="storyCon-2">
                        <p class="storyCon2">The Bored Sharks Club NFT Project emerged from the desire to make career development and progression accessible to everyone, not just those who can afford high-priced <span class="hlSpan2">"Coach Gurus."</span> These so-called experts often charge exorbitant fees, leaving average workers without the resources they need to grow.</p>
                        <p class="storyCon2">Our founder, leveraging his <span class="hlSpan2">26-year career</span> in the Energy Sector and a network of reputable subject matter experts, aims to create an inclusive and supportive community within the Bored Sharks Club. By focusing on collaboration and learning, we believe that everyone can prosper together.</p>
                        <p class="storyCon2">We acknowledge that building such a network requires effort and investment. To make the Bored Sharks Club more accessible, we've reduced the mint price to a nominal <span class="hlSpan2">0.077 ETH per Shark</span>. This rate is significantly more affordable than the fees charged by typical "Career Gurus," many of whom deliver little to no results.</p>
                        <p class="storyCon2">The Bored Sharks Club invites you to <span class="hlSpan2">join our community</span> of professionals from around the world, united in a shared mission to learn and grow. Together, we'll navigate the ocean of opportunities, building a strong and lasting network that benefits us all. This is just the beginning of an incredible journey, and we're excited to have you along for the ride.</p>
                    </div>
                </div>


            </div>
        )
    }
}

export default Story2;

